import styles from "./Modal.module.scss";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { cn } from "@/lib/utils";

function Modal({ children, isOpen, setIsOpen, panelClass }) {
  return (
    <Transition appear show={isOpen}>
      <Dialog
        as="div"
        className={cn("relative z-[9999]")}
        onClose={() => setIsOpen(false)}
      >
        <Transition.Child
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className={cn(
                  styles[panelClass],
                  "w-[95vw] mx-4 max-w-5xl transform overflow-hidden rounded-2xl bg-white p-4 text-left align-middle shadow-xl transition-all dialog-panel",
                )}
              >
                <button
                  className="hover:bg-blue-light rounded transition-colors p-1 block ml-auto mb-2 -mr-1 -mt-1"
                  onClick={() => setIsOpen(false)}
                >
                  <XMarkIcon className="w-8 h-8" />
                </button>

                <div>{children}</div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export { Modal };
