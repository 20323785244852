import { AnimatePresence, motion } from "framer-motion";
import { useRouter } from "next/router";
import { cn } from '@/lib/utils'

const variants = {
  in: {
    opacity: 1,
    scale: 1,
    y: 0,
    transition: {
      duration: 0.3,
      delay: 0.125,
    },
  },
  out: {
    opacity: 0,
    scale: 1,
    y: 40,
    transition: {
      duration: 0.3,
    },
  },
};

function Transition({ className, children }) {
  const { asPath } = useRouter();

  return (
    <AnimatePresence
      mode="await"
      onExitComplete={() => {
        if (typeof window !== "undefined") {
          window.scrollTo({ top: 0 });
        }
      }}
    >
      <motion.div
        className={cn(className)}
        key={asPath}
        variants={variants}
        animate="in"
        initial="out"
        exit="out"
      >
        {children}
      </motion.div>
    </AnimatePresence>
  );
}

export { Transition };
