import styles from "./AppNavigation.module.scss";
import {
  ArrowRightIcon,
  Bars3Icon,
  ChevronDownIcon,
  MagnifyingGlassIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { cn } from "@/lib/utils";
import Link from "next/link";
import { useRouter } from "next/router";
import { useCallback, useEffect, useRef, useState } from "react";
import SVG from "react-inlinesvg";
import Logo from "@/assets/svg/logo.svg";
import { Button } from "@/components/Button";

function AppNavigation({ theme, menu, products, useCases }) {
  const { asPath } = useRouter();
  const [isOpen, setIsOpen] = useState(false);
  const [openItemId, setOpenItemId] = useState(null);
  const [windowSize, setWindowSize] = useState([0, 0]);
  const navRef = useRef(null);

  const menuItems = menu
    .filter((item) => !item.parentId)
    .map((item) => {
      if (item.childItems.nodes.length === 0) return item;

      let hasUseCase = false;
      const value = item.childItems.nodes.map((node) => {
        const product = products.find((product) =>
          node.uri.includes(product.uri),
        );
        const useCase = useCases.find((useCase) =>
          node.uri.includes(useCase.uri),
        );
        const icon =
          product?.productCategoryAcf?.icon ||
          useCase?.productCategoryAcf?.icon;
        if (!hasUseCase && useCase) hasUseCase = true;

        return { ...node, useCase, icon };
      });

      return { ...item, hasUseCase, childItems: { nodes: value } };
    });

  useEffect(
    () =>
      isOpen
        ? disableBodyScroll(navRef.current)
        : enableBodyScroll(navRef.current),
    [isOpen],
  );

  useEffect(() => {
    setOpenItemId(null);
    setIsOpen(false);
    setWindowSize([window.innerWidth, window.innerHeight]);

    document.activeElement.blur();
  }, [asPath]);

  function setMenuIndicatorToActiveMenuItem() {
    const active = document.querySelector('ul[role="menubar"] .active');
    updateMenuIndicatorVariables(active);
  }

  function updateMenuIndicatorVariables(currentMenuItem) {
    const menubar = document.querySelector('[role="menubar"]');
    if (!navRef.current) return;
    if (currentMenuItem) {
      navRef.current.style.setProperty(
        "--active-menu-top",
        currentMenuItem.offsetTop + menubar.offsetTop + "px",
      );
      navRef.current.style.setProperty(
        "--active-menu-left",
        currentMenuItem.offsetLeft + "px",
      );
      navRef.current.style.setProperty(
        "--active-menu-width",
        currentMenuItem.offsetWidth + "px",
      );
      navRef.current.style.setProperty(
        "--active-menu-height",
        currentMenuItem.offsetHeight + "px",
      );
    } else {
      navRef.current.style.setProperty(
        "--active-menu-top",
        menubar.offsetTop + "px",
      );
      navRef.current.style.setProperty(
        "--active-menu-left",
        menubar.offsetLeft + "px",
      );
      navRef.current.style.setProperty("--active-menu-width", 0);
      navRef.current.style.setProperty("--active-menu-height", 0);
    }
  }

  useEffect(() => {
    const currentActiveMenuItem = document.querySelector(
      '[role="menuitem"].active',
    );
    const update = () =>
      setTimeout(
        () => updateMenuIndicatorVariables(currentActiveMenuItem),
        100,
      );

    update();

    window.addEventListener("resize", update);
    return () => window.removeEventListener("resize", update);
  });

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  function isActiveItem(item) {
    if (item.childItems.nodes.length === 0) return asPath.includes(item.uri);
    const data = [];

    data.push(item);

    function getChildItems(parent) {
      if (!parent.childItems) return data.push(parent);
      parent.childItems.nodes.forEach((node) =>
        getChildItems(node.childItems.nodes),
      );
    }

    getChildItems(item);
    return data.flat(Infinity);
  }

  useEffect(() => {
    document.addEventListener("click", (event) => {
      if (navRef.current?.contains(event.target)) return;
      setOpenItemId(null);
    });
  }, []);

  /**
   * Get depth of menu item to see if it has nested menu items
   * @type {(function(*, number=): (*))|*}
   */
  const getDepth = useCallback((item, depth = 0) => {
    if (item?.childItems?.nodes?.length) {
      return Math.max(
        ...item.childItems.nodes.map((item) => getDepth(item, depth + 1)),
      );
    }
    return depth;
  }, []);

  return (
    <nav ref={navRef} data-is-open={isOpen} className={styles.nav}>
      <span className={styles.indicator} />

      <div className={styles.wrapper}>
        {/* Logo */}
        <Link
          scroll={false}
          href="/"
          className={styles.logo}
          title="Home"
          aria-label="Home"
        >
          <Logo />
          <h1 className="sr-only">KPMG Sofy Suite</h1>
        </Link>

        {/* Menu items */}
        <ul
          role="menubar"
          className={styles.menubar}
          onMouseOut={setMenuIndicatorToActiveMenuItem}
        >
          {menuItems.map((item) => {
            return (
              <li
                key={item.id}
                role="none"
                className={cn({
                  [styles["has-small-submenu"]]: getDepth(item) === 1,
                })}
                onMouseOver={(event) =>
                  updateMenuIndicatorVariables(
                    event.currentTarget.querySelector('[role="menuitem"]'),
                  )
                }
              >
                {getDepth(item) > 0 ? (
                  <>
                    {((item.hasUseCase && windowSize[0] >= 1024) ||
                      !item.hasUseCase) && (
                      <Link
                        scroll={false}
                        href={item.uri}
                        title={item.label}
                        role="menuitem"
                        aria-haspopup={true}
                        className={cn({
                          active: isActiveItem(item)
                            .map((item) => item.uri)
                            .includes(asPath),
                        })}
                        aria-expanded={openItemId === item.id}
                        onClick={(event) => {
                          event.preventDefault();
                          if (openItemId === item.id)
                            return setOpenItemId(null);
                          setOpenItemId(item.id);
                        }}
                      >
                        {item.label}
                        <ChevronDownIcon />
                      </Link>
                    )}
                    {item.hasUseCase && windowSize[0] < 1024 && (
                      <Link
                        scroll={false}
                        role="menuitem"
                        href={item.uri}
                        className={cn({ active: asPath.includes(item.uri) })}
                      >
                        {item.label}
                      </Link>
                    )}
                    {((item.hasUseCase && windowSize[0] >= 1024) ||
                      !item.hasUseCase) &&
                      (getDepth(item) > 1 ? (
                        <div className={styles.submenu}>
                          <ul
                            role="menu"
                            aria-label={item.label}
                            className={styles["submenu__grid"]}
                          >
                            {item.childItems.nodes.map((category) => (
                              <li
                                key={category.id}
                                role="none"
                                className={styles.category}
                              >
                                <span className="icon">
                                  <SVG
                                    className="svg svg--white"
                                    src={category?.icon?.sourceUrl}
                                  />
                                </span>
                                <div>
                                  {category?.useCase ? (
                                    <span className={styles["label"]}>
                                      {category.label}
                                    </span>
                                  ) : (
                                    <Link
                                      scroll={false}
                                      href={category.uri}
                                      title={category.label}
                                      role="menuitem"
                                      aria-haspopup={true}
                                      aria-expanded={true}
                                    >
                                      {category.label}
                                    </Link>
                                  )}
                                  <ul
                                    role="menu"
                                    aria-label={category.label}
                                    className={styles["solution-list"]}
                                  >
                                    {category.childItems.nodes.map(
                                      (solution) => {
                                        return (
                                          <li key={solution.id} role="none">
                                            <Link
                                              scroll={false}
                                              href={solution.uri}
                                              title={solution.label}
                                              role="menuitem"
                                            >
                                              {solution.label}
                                            </Link>
                                          </li>
                                        );
                                      },
                                    )}
                                  </ul>
                                </div>
                              </li>
                            ))}
                            <li role="none" className={styles["overview-link"]}>
                              <Link
                                scroll={false}
                                href={item.uri}
                                title={`${theme.discoverAllText} ${item.label.toLowerCase()}`}
                                role="menuitem"
                              >
                                {theme.discoverAllText}{" "}
                                {item.label.toLowerCase()}
                                <ArrowRightIcon className="w-5 h-5 ml-2 shrink-0" />
                              </Link>
                            </li>
                          </ul>
                        </div>
                      ) : (
                        <ul
                          role="menu"
                          aria-label={item.label}
                          className={styles["submenu-small"]}
                        >
                          {item.childItems.nodes.map((category) => (
                            <li
                              key={category.id}
                              role="none"
                              className={styles["submenu-item"]}
                            >
                              <Link
                                scroll={false}
                                href={category.uri}
                                title={category.label}
                                role="menuitem"
                                aria-haspopup={true}
                                aria-expanded={true}
                              >
                                {category.label}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      ))}
                  </>
                ) : (
                  <Link
                    scroll={false}
                    role="menuitem"
                    href={item.uri}
                    className={cn({ active: isActiveItem(item) })}
                  >
                    {item.label}
                  </Link>
                )}
              </li>
            );
          })}
        </ul>

        {/* Actions */}
        <div className={styles.actions}>
          <Link
            scroll={false}
            title="Search"
            aria-label="Search"
            href={theme.searchPage.uri}
            className={styles.search}
          >
            <MagnifyingGlassIcon />
          </Link>

          <button
            name="Menu"
            aria-label="Menu"
            className={styles.toggle}
            onClick={() => setIsOpen(!isOpen)}
          >
            <Bars3Icon />
            <XMarkIcon />
          </button>
        </div>

        <div className={styles.button}>
          <Button href={theme.requestDemoPage.uri} color="pink">
            {theme.requestDemoPage.title}
          </Button>
        </div>
      </div>
    </nav>
  );
}

export { AppNavigation };
