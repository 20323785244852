import Head from "next/head";
import { useRouter } from "next/router";

function AppSeo({ seo }) {
  const { asPath } = useRouter();

  const seoValues = {
    title: seo ? seo.title : "KPMG Sofy",
    description: seo ? seo.metaDesc : "",
    image: seo && seo.opengraphImage ? seo.opengraphImage.sourceUrl : null,
    url: process.env.NEXT_PUBLIC_APP_URL + asPath,
    follow: seo ? seo.metaRobotsNofollow : "follow",
    index: seo ? seo.metaRobotsNoindex : "index",
  };

  return (
    <Head>
      <meta httpEquiv="X-UA-Compatible" content="IE=Edge" />
      <title>{seoValues.title}</title>

      <meta property="og:locale" content="en_US" />

      <meta
        name="robots"
        content={`max-snippet:-1, max-image-preview:large, max-video-preview:-1, ${seoValues.follow}, ${seoValues.index}`}
      />

      <meta name="viewport" content="initial-scale=1.0, width=device-width" />

      <meta property="og:site_name" content={seoValues.opengraphSiteName} />

      <meta property="title" content={seoValues.title} />

      {seoValues?.description && (
        <meta name="description" content={seoValues.description} />
      )}

      <meta property="og:title" content={seoValues.title} />

      {seoValues?.description && (
        <meta name="og:description" content={seoValues.description} />
      )}

      <meta property="og:url" content={seoValues.url} />

      {seoValues.image && (
        <meta property="og:image" content={seoValues.image} />
      )}

      <meta property="og:type" content="website" />
    </Head>
  );
}

export { AppSeo };
