import Link from "next/link";
import Logo from "@/assets/svg/logo.svg";
import { NewsletterForm } from "@/blocks/global/NewsletterForm";

function AppFooter({ theme, footer }) {
  const backgroundFooter =
    "linear-gradient(to right, rgba(229, 235, 244, 0.9), rgba(229, 235, 244, 0.9)), url(/images/footer-background.png)";

  return (
    <footer>
      <div
        style={{ backgroundImage: backgroundFooter }}
        className="container bg-left bg-no-repeat bg-cover md:bg-center"
      >
        <div className="py-12 sm:py-20 md:py-12 lg:py-24">
          <div className="container--grid !gap-x-12 !gap-y-10 text-base-700 max-w-lg mx-auto md:max-w-4xl">
            <div className="col-span-full md:col-span-4 text-sm">
              <Logo className="h-10" />
              <p className="mt-2">{theme.footerSofyInfo}</p>
            </div>

            <div className="col-span-full md:col-span-4">
              <p className="text-base font-bold text-base-900">
                {theme.footerNewsletterTitle}
              </p>
              <p className="mt-2">{theme.footerNewsletterText}</p>

              <NewsletterForm theme={theme} className="mt-2" />
            </div>

            <div className="col-span-full md:col-span-4 grid sm:grid-cols-2 gap-8 text-sm">
              <div>
                <p className="text-base font-bold text-base-900">
                  {footer.about.name}
                </p>
                <ul className="grid gap-2 mt-2">
                  {footer.about.menuItems.nodes.map((item) => {
                    return (
                      <li key={item.uri}>
                        <Link
                          scroll={false}
                          href={item.uri}
                          target={item.target}
                          className="hover:underline"
                        >
                          {item.label}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>

              <div>
                <p className="text-base font-bold text-base-900">
                  {theme.productsFooterText}
                </p>
                <ul className="grid gap-2 mt-2">
                  {footer.products.map((item) => {
                    return (
                      <li key={item.uri}>
                        <Link
                          scroll={false}
                          href={item.uri}
                          className="hover:underline"
                        >
                          {item.name}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>

            <div className="col-span-full md:col-span-4 grid sm:grid-cols-2 gap-8 text-sm">
              <div>
                <p className="text-base font-bold text-base-900">
                  {footer.contact.name}
                </p>
                <ul className="grid gap-2 mt-2">
                  {footer.contact.menuItems.nodes.map((item) => {
                    return (
                      <li key={item.uri}>
                        <Link
                          scroll={false}
                          href={item.uri}
                          target={item.target}
                          className="hover:underline"
                        >
                          {item.label}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>

              <div>
                <p className="text-base font-bold text-base-900">
                  {footer.links.name}
                </p>
                <ul className="grid gap-2 mt-2">
                  {footer.links.menuItems.nodes.map((item) => {
                    return (
                      <li key={item.uri}>
                        <Link
                          scroll={false}
                          href={item.uri}
                          target={item.target}
                          className="hover:underline"
                        >
                          {item.label}
                        </Link>
                      </li>
                    );
                  })}
                  <li>
                    <button
                      type="button"
                      id="ot-sdk-btn"
                      className="hover:underline ot-sdk-show-settings"
                    >
                      Cookie setting
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className=" container bg-blue-light">
        <div className="container py-6 text-xs text-base-900">
          {theme.footerLegalText}
        </div>
      </div>
    </footer>
  );
}

export { AppFooter };
