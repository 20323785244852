import styles from "./Button.module.scss";
import * as heroIcons from "@heroicons/react/24/outline";
import { cn } from "@/lib/utils";
import Link from "next/link";

function Button({
  href = null,
  type = "submit",
  outline = false,
  icon = "ArrowLongRightIcon",
  color = null,
  loading = false,
  className,
  children,
  disabled,
  ...props
}) {
  const HeroIcon = heroIcons[icon];

  return href ? (
    <Link
      scroll={false}
      href={href}
      className={cn(
        styles.button,
        { [styles["button--outline"]]: outline },
        className,
        styles[`button--${color}`],
      )}
      tabIndex={loading || disabled ? -1 : 0}
      {...props}
    >
      <span>{children}</span>
      {HeroIcon && !loading && <HeroIcon />}
      {loading && <LoadingSpinner className={cn(styles["loading"])} />}
    </Link>
  ) : (
    <button
      type={type}
      className={cn(
        styles.button,
        { [styles["button--outline"]]: outline },
        className,
        styles[`button--${color}`],
      )}
      disabled={disabled || loading}
      {...props}
    >
      <span>{children}</span>
      {HeroIcon && !loading && <HeroIcon />}
      {loading && <LoadingSpinner className={cn(styles["loading"])} />}
    </button>
  );
}

function LoadingSpinner({ className, ...props }) {
  return (
    <svg
      className={cn("animate-spin", { [className]: className })}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <circle
        className="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        strokeWidth="4"
      ></circle>
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
  );
}

export { Button };
