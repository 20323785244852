import { cn } from "@/lib/utils";
import { useRouter } from "next/router";
import { AppFooter } from "@/components/AppFooter";
import { AppNavigation } from "@/components/AppNavigation";
import { AppSeo } from "@/components/AppSeo";
import { AutomaticPopup } from "@/components/AutomaticPopup";
import NoSsr from "@/components/NoSsr";
import { Transition } from "@/components/Transition";

const AppLayout = ({ seo, theme, layout, className, children }) => {
  const menu = layout.mainMenu.nodes;

  const footer = {
    about: layout.footerAbout.nodes[0],
    products: layout.footerProducts.nodes.filter(
      (category) => category.products.nodes.length,
    ),
    contact: layout.footerContact.nodes[0],
    links: layout.footerLink.nodes[0],
  };

  const router = useRouter();
  const currentPath = router.asPath;

  const popups = theme.popups;
  const activePopup = (popups || []).filter(
    (popup) => popup.page.uri === currentPath,
  )[0];

  return (
    <>
      <AppSeo seo={seo} />

      <NoSsr>
        <AppNavigation
          theme={theme}
          menu={menu}
          products={footer.products}
          useCases={layout.useCaseCategories.nodes}
        />

        <Transition>
          <main className={cn({ [className]: className })}>{children}</main>

          <AppFooter theme={theme} footer={footer} />
        </Transition>

        {activePopup && (
          <AutomaticPopup
            currentPath={currentPath}
            activePopup={activePopup}
            theme={theme}
          />
        )}

        <noscript>
          <iframe
            src={`https://www.googletagmanager.com/ns.html?id=${process.env.NEXT_PUBLIC_GTM_TRACKING_ID}`}
            height="0"
            width="0"
            title="GTM"
            style={{ display: "none", visibility: "hidden" }}
          />
        </noscript>
      </NoSsr>
    </>
  );
};

export default AppLayout;
