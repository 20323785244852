import Image from "next/image";
import { useEffect, useState } from "react";
import { NewsletterForm } from "@/blocks/global/NewsletterForm";
import { Button } from "@/components/Button";
import { Modal } from "@/components/Modal";

function AutomaticPopup({ currentPath, activePopup, theme }) {
  const [isOpen, setIsOpen] = useState(false);
  const cookie = getCookie(`modal_${currentPath}`);

  useEffect(() => {
    const showAfter = Number(activePopup.showAfterXSeconds) * 1000;
    if (typeof window !== "undefined" && !cookie) {
      setTimeout(() => {
        openModal();
        const d = new Date();
        d.setTime(d.getTime() + 1 * 24 * 60 * 60 * 1000);
        const expires = `expires=${d.toUTCString()}`;
        if (typeof window !== "undefined") {
          window.document.cookie = `modal_${currentPath}=closed; ${expires}`;
        }
      }, showAfter);
    }
  });

  function openModal() {
    setIsOpen(true);
  }

  return (
    <Modal isOpen={isOpen} panelClass="small-panel" setIsOpen={setIsOpen}>
      <div className="flex flex-wrap w-full md:flex-no-wrap mb-8">
        <div className="w-full md:w-2/5 lg:w-1/2">
          <Image
            fill
            className="w-[90px] ml-0 pl-4 md:pl-0 md:w-full md:mx-auto h-full max-w-[160px]"
            alt={activePopup.popupIcon.altText}
            src={activePopup.popupIcon.sourceUrl}
          />
        </div>
        <div className="w-full px-6 pt-5 md:w-2/5 lg:w-1/2 md:pt-0 md:px-0 lg:pr-8">
          <h2 className="heading heading--2xl">{activePopup.popupTitle}</h2>
          <p className="pb-6 text-base md:text-lg text-gray-66">
            {activePopup.popupText}
          </p>

          {activePopup.typePopup === "call_to_action" ? (
            <Button
              rel="noreferrer"
              href={
                activePopup.popupExternalButtonUrl === "external"
                  ? activePopup.buttonUrlExternal
                  : activePopup.buttonUrl.uri
              }
              target={
                activePopup.openCustomUrlInANewTab === "yes" ? "_blank" : ""
              }
            >
              {activePopup.buttonText}
            </Button>
          ) : (
            <NewsletterForm theme={theme} />
          )}
        </div>
      </div>
    </Modal>
  );
}

function getCookie(name) {
  if (typeof window !== "undefined") {
    const value = `; ${window.document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
    return false;
  }
  return false;
}

export { AutomaticPopup };
