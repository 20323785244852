import { cn } from "@/lib/utils";
import { useRef, useState } from "react";
import { Button } from "@/components/Button";
import { Input } from "@/components/Input";
import useUniqueId from "@/hooks/useUniqueId";

function NewsletterForm({ theme, className, ...props }) {
  const { id } = useUniqueId();
  const formRef = useRef(null);
  const [isSubmittedSuccessfully, setIsSubmittedSuccessfully] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  async function handleSubmit(event) {
    event.preventDefault();
    const form = event.target;
    const formData = new FormData(formRef.current);

    try {
      setLoading(true);

      const response = await handleFormSubmission(
        JSON.stringify(Object.fromEntries(formData)),
      );
      if (!response.ok) throw response;

      if (typeof window !== "undefined") {
        window.dataLayer.push("event", "newsletter-subscription", {
          event_category: "leads",
        });
      }

      form.reset();
      setIsSubmittedSuccessfully(true);
      setError(false);
    } catch (error) {
      // console.error(error)
      setError(true);
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      {!isSubmittedSuccessfully ? (
        <form
          ref={formRef}
          onSubmit={handleSubmit}
          className={cn("grid gap-2", { [className]: className })}
          {...props}
        >
          <label className="sr-only" htmlFor="honeypot">
            Honeypot
            <Input
              className="absolute -top-[200vh] -left-[200vw]"
              name="honeypot"
              id="honeypot"
              type="text"
              tabIndex="-1"
            />
          </label>

          <div className="flex items-center">
            <label htmlFor="email" className="sr-only">
              Email
            </label>
            <Input
              className="[&:not(:valid)]:!border-base-500 [&:not(:valid)]:!border-r-0 [&:valid]:!border-base-500 [&:valid]:!border-r-0 rounded-tl-lg rounded-bl-lg"
              color="transparent"
              rounded={false}
              name="email"
              type="email"
              autoComplete="on"
              placeholder={theme.emailAddressPlaceholder}
              required
              pattern="[A-Za-z0-9._+-]+@[A-Za-z0-9 -]+\.[a-z]{2,}"
              onInvalid={(event) => {
                let error = "";
                const validity = event.target.validity;

                if (validity.valueMissing)
                  error = theme.pleaseFillInYourEmailText;
                if (validity.patternMismatch)
                  error = theme.pleaseEnterAValidEmailAddressText;

                return event.target.setCustomValidity(error);
              }}
            />

            <Button
              icon={false}
              loading={loading}
              type="submit"
              color="blue-pacific"
              className="bg-blue-pacific h-12 text-white !py-2 !px-3 !font-medium text-base rounded-tr-lg rounded-br-lg"
            >
              {theme.subscribeButtonText}
            </Button>
          </div>

          <p className="flex items-start space-x-2">
            <Input color="base-500" name="consent" type="checkbox" id={id} />

            <label htmlFor={id} className="text-xs">
              {theme.consentText}
            </label>
          </p>

          {error && (
            <p className="text-ui-error text-xs">
              {theme.somethingWentWrongText}
            </p>
          )}
        </form>
      ) : (
        <p className={cn("mt-2 heading heading--lg")}>
          {theme.thankYouSuccessMessage}
        </p>
      )}
    </>
  );
}

async function handleFormSubmission(data) {
  const { honeypot, email, consent } = JSON.parse(data);

  const missingRequiredFields = !email;
  if (honeypot || missingRequiredFields) throw { status: 422, ok: false };

  try {
    const url =
      process.env.NEXT_PUBLIC_API_URL + "/wp/wp-json/salesforce/v1/newsletter/";
    const body = `email=${email}&emailConsent=${!!consent}`;

    const response = await fetch(url, {
      method: "POST",
      body,
    });

    if (!response.ok) throw response;
    return response;
  } catch (error) {
    return error;
  }
}

export { NewsletterForm };
