import { useEffect, useState } from "react";

function useUniqueId() {
  const [id, setId] = useState(null);

  useEffect(() => {
    function generateRandomID() {
      return Math.random().toString(16).slice(2);
    }

    setId(generateRandomID());
  }, []);

  return { id };
}

export default useUniqueId;
