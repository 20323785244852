import styles from "./Input.module.scss";
import { cn } from "@/lib/utils";
import { forwardRef } from "react";

function InputRoot(
  { className, color = null, rounded = true, onInvalid, ...props },
  ref,
) {
  return (
    <input
      ref={ref}
      className={cn(
        styles.input,
        { [className]: className, [styles["input--rounded"]]: rounded },
        styles[`input--${color}`],
      )}
      placeholder={props.placeholder || " "}
      onInvalid={(event) => {
        if (onInvalid) onInvalid(event);
        event.target.classList.add(styles["input--invalid"]);
      }}
      onInput={(event) => {
        event.target.checkValidity();
        if (event.target.validity.valid)
          return event.target.classList.remove(styles["input--invalid"]);
      }}
      {...props}
    />
  );
}

export const Input = forwardRef(InputRoot);
